<script>
export default {
    name: 'BaseTextarea',

    inheritAttrs: false,

    props: {
        value: {
            type: String,
        },
    },

    data() {
        return {
            focused: false,
            uid: undefined,
        }
    },

    mounted() {
        const inBrowser = typeof window !== 'undefined'
        if (inBrowser) {
            this.uid = this.$attrs.id ? this.$attrs.id : 'textarea' + Math.random()
        }
    },
}
</script>

<template>
    <div
        class="base-textarea"
        :class="{
            ...$attrs.class,
            'has-value': value && value.length,
            focused: focused,
        }"
    >
        <label :for="`${uid}`">
            <div class="label-text">
                <slot name="label" />
            </div>
        </label>
        <div class="textarea-wrapper">
            <div class="before">
                <slot name="before" />
            </div>
            <textarea
                rows="3"
                @focus="focused = true"
                @blur="focused = false"
                :value.prop="value"
                v-on="$listeners"
                :id="`${uid}`"
                v-bind="$attrs"
            ></textarea>
            <div class="after">
                <slot name="after" />
            </div>
        </div>
    </div>
</template>
<style lang="stylus">
@import '../assets/stylus/vars.styl'

.base-textarea
    font-size 1.4em
    display block
    position relative
    cursor text

    label
        padding-bottom 5px
        display block
        padding-left 28px

    .label-text
        display block
        font-weight normal
        color $AkBlack

    .textarea-wrapper
        display flex
        align-items start
        width auto 
        border 1px solid var(--color3)
        border-radius 10px
        background $AkWhite
        min-height 3.8em
        padding-top 18px
        padding-left 28px

        textarea
            flex-grow 1
            flex-shrink 1
            border 0
            min-width 0
            height 100%
            box-sizing border-box
            background transparent
            color $AkGreyscaleBlack
            font-family inherit
            font-size 16px
            resize vertical
            padding-bottom 18px

            &:focus
                outline 0

            &::placeholder
                color var(--AkGreyscaleMediumGrey)

    &.focused
        border-color var(--color5)

    &.has-errors:not(.focused)
        .textarea-wrapper
            border-color $AkMajorCoral

        .label-text
            color $AkMajorCoral

        .after
        .before
            color $AkMajorCoral

    &.is-success:not(.focused)
        .textarea-wrapper
            border-color $AkMainGreen

        .label-text
            color $AkMainGreen

        .after
        .before
            color $AkMainGreen

    &.base-textarea-small
        font-size 1.15em

        .label-text
            font-size .9em
</style>
