<script>
function debounce( callback, delay ) {
  let timer;

  return( ...args ) => {
    return new Promise( ( resolve, reject ) => {
      clearTimeout(timer);
      timer = setTimeout( () => {
          try {
            let output = callback(...args);
            resolve( output );
          } catch ( err ) {
            reject( err );
          }
      }, delay );
    })

  }
}

function extractZipcode(string) {
    const matches = string.match(/\b\d{5}\b/g)
    if (matches && matches[0] && matches[0].length) {
        return matches[0]
    }
    return null
}

// Takes 2 types of value :
// 1. string when the user types
// 2. object when the user clicks a result
// maybe create a validator to only allow "object" values

export default {
    name: 'BaseZipcode',

    inheritAttrs: false,

    props: {
        value: {
            type: [String, Object],
        },
    },

    data() {
        return {
            debouncedFetch: undefined,
            results: undefined,
            focused: undefined,
            clickedOutside: undefined,
            loading: undefined,
            autoSelected: undefined,
            uid: undefined,
            isTouchDevice: undefined
        }
    },

    computed: {
        open() {
            return this.focused && !this.clickedOutside && this.results && this.results.length > 1
        },

        displayableValue() {
            if (typeof this.value === 'object') {
                return `${this.value.cpCode} - ${this.value.communeLib}`
            } else {
                return this.value || ''
            }
        }
    },

    watch: {
        value: {
            handler: async function () {
                if (typeof this.value === 'object') {
                    return
                }
                if (!this.value || this.value.length < 3) {
                    return
                }
                if (this.debouncedFetch) {
                    const zipcodeInValue = extractZipcode(this.value)
                    if (zipcodeInValue) {
                        const [ cpCode, communeLib ] = this.value.split(' - ')
                        const results = await this.debouncedFetch(zipcodeInValue)

                        const foundResult = results.find((result) => {
                            return result.cpCode === cpCode && result.communeLib === communeLib
                        })

                        if (foundResult) {
                            this.results = null
                            this.$emit('valueChange', foundResult)
                            this.autoSelected = true
                        } else {
                            this.results = results
                            this.$emit('valueChange', results[0])
                            this.autoSelected = true
                        }
                    } else {
                        const results = await this.debouncedFetch(this.value)

                        this.results = results
                        if (this.results.length === 1) {
                            this.$emit('valueChange', this.results[0])
                            this.autoSelected = true
                        }
                    }
                }
            },

            immediate: true
        },
    },

    async mounted() {
        const inBrowser = typeof window !== 'undefined'
        if (inBrowser) {
            this.uid = this.$attrs.id ? this.$attrs.id : 'zipcode' + Math.random()
            this.isTouchDevice = 'ontouchstart' in document.documentElement
        }

        this.debouncedFetch = debounce(this.fetch, 600)

        if (typeof this.value !== 'object' && this.value && this.value.length >= 3) {
            let results
            const zipcodeInValue = extractZipcode(this.value)
            if (zipcodeInValue) {
                const [ cpCode, communeLib ] = this.value.split(' - ')
                results = await this.fetch(zipcodeInValue)

                const foundResult = results.find((result) => {
                    return result.cpCode === cpCode && result.communeLib === communeLib
                })

                if (foundResult) {
                    this.results = null
                    this.$emit('valueChange', foundResult)
                    this.autoSelected = true
                } else {
                    this.results = results
                    this.$emit('valueChange', results[0])
                    this.autoSelected = true
                }
            } else {
                results = await this.fetch(this.value)

                this.results = results
                if (this.results.length === 1) {
                    this.$emit('valueChange', this.results[0])
                    this.autoSelected = true
                }
            }
        }

        document.body.addEventListener('click', this.handleClickOutside)
    },

    beforeDestroy() {
        document.body.removeEventListener('click', this.handleClickOutside)
    },

    methods: {
        fetch(input) {
            const endpoint = 'https://contact.acadomia.fr/api/adresse/rechercherCpCommune'

            this.loading = true

            return fetch(`${endpoint}/?cpCommune=${input}`)
                .then((response) => response.json())
                .then((json) => json).then(({ cpCommunes }) => {
                    this.loading = false

                    return cpCommunes
                })
        },

        handleInput($event) {
            if (this.autoSelected && $event.currentTarget.value.length > this.displayableValue.length) {
                this.$emit('valueChange', this.value)
                this.autoSelected = false
            } else {
                const value = $event.currentTarget.value
                this.$emit('valueChange', value)
                this.autoSelected = false
                this.clickedOutside = false
            }
        },

        handleResultClick(result) {
            this.focused = false
            this.results = undefined
            this.$emit('valueChange', result)
        },

        handleFocus() {
            this.focused = true
            this.clickedOutside = false
        },

        handleBlur() {
            this.focused = false
        },

        handleClickOutside(event) {
            if (!(this.$refs.multilist == event.target || this.$refs.multilist.contains(event.target))) {
                this.clickedOutside = true
            }
        },
    },
}
</script>

<template>
    <div class="base-zipcode base-input"
      :class="{
        ...$attrs.class,
        'has-value': displayableValue && displayableValue.length,
        'is-dropdown-value': typeof this.value === 'object',
        'is-loading': loading,
        'is-open': open,
        'focused': focused,
        'has-results': results && results.length > 0,
        'has-many-results': results && results.length > 1,
        'not-touch-device': !isTouchDevice
    }">
        <label :for="uid">
            <div class="label-text">
                <slot name="label" />
            </div>
        </label>
        <div ref="multilist" class="multilist">
            <div class="input-wrapper">
                <div class="before">
                    <slot name="before" />
                </div>
                <input class="multilist-input"
                  @input="handleInput($event)"
                  @focus="handleFocus($event)"
                  @blur="handleBlur($event)"
                  v-on="$listeners"
                  :value="displayableValue"
                  :id="uid"
                  v-bind="$attrs"
                  type="text" />

                <div class="after">
                    <slot name="after" />
                </div>
            </div>

            <div class="multilist-results" v-if="open">
                <div v-for="result in results"
                  class="multilist-result"
                  :key="result.cpId + '-' + result.communeId"
                  @mousedown="handleResultClick(result)">
                    {{ result.cpCode }} - {{ result.communeLib }}
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="stylus">
@import '../assets/stylus/vars.styl'

.base-input.base-zipcode
    font-size 1.4em
    display block
    position relative
    cursor text

    &.not-touch-device .multilist-result:hover
        background var(--color3)
        color var(--color3Inverse)

    .multilist
        position relative

    .multilist-results
        z-index 4
        position absolute
        top 100%
        left 30px
        right 30px
        background #fff
        margin-top -1px
        border 1px solid var(--color3)
        max-height 250px
        overflow auto

    .multilist-result
        cursor pointer
        padding 5px

    .label-text
    input
        // padding-left 1em
        // padding-right 1em

    label
        padding-left 2em
        cursor text
        z-index 1
        display block
        padding-bottom 3px

    .label-text
        display block
        // color $AkGreyscaleBlack
        color $AkBlack

    .input-wrapper
        box-sizing border-box
        display flex
        align-items center
        opacity 1
        border 1px solid var(--color3)
        border-radius 9999px
        background $AkWhite
        height 3em
        font-size inherit
        padding-left 2em
        padding-right 2em

        input
            flex-grow 1
            flex-shrink 1
            border 0
            min-width 0
            height 100%
            box-sizing border-box
            color var(--AkGreyscaleBlack)
            font-family inherit
            font-size inherit

            &::placeholder
                color var(--AkGreyscaleMediumGrey)
                font-weight: normal
                font-size: 16px

            &:focus
                outline 0

                &::placeholder
                    color var(--AkGreyscaleMediumGrey)

    &.focused
    &.has-value
        label
            z-index 0

        .label-text
            font-weight normal
            color var(--color5)

        .input-wrapper
            opacity 1

    &.focused
        border-color var(--color5)

    &.has-errors:not(.focused)
        .input-wrapper
            border-color $AkMajorCoral

        .label-text
            color $AkMajorCoral

        .after
        .before
            color $AkMajorCoral

    &.is-success:not(.focused)
        .input-wrapper
            border-color $AkMainGreen

        .label-text
            color $AkMainGreen

        .after
        .before
            color $AkMainGreen

    &.base-input-small
        font-size 1.15em
</style>
