<script>
import { render } from 'micromustache'
import CourseCard from '@/components/CourseCard.vue'
import BaseLoader from '@/components/BaseLoader.vue'

import {
    BaseButton,
    BaseMultiSelect,
    BaseZipcode,
    BaseCheckbox,
    IconCross,
    IconArrowLeft,
    IconArrowRight,
    IconArrowheadLeft,
    IconArrowheadRight,
} from 'aca-design'

export default {

    name: 'HomeScreen',

    components: {
        BaseLoader,
        BaseButton,
        BaseMultiSelect,
        BaseZipcode,
        BaseCheckbox,
        IconCross,
        IconArrowLeft,
        IconArrowRight,
        IconArrowheadLeft,
        IconArrowheadRight,
        CourseCard,
    },

    props: {
        model: {
            type: Object,
            default() {
                return {}
            }
        },
        coursesLoading: {
            type: Boolean,
        },
        isStage: {
            type: Boolean,
            default: true
        },
        matchingCourses: {
            type: Array,
        },
        matchingCoursesLength: {
            type: Number,
        },
        filteredLevels: {
            type: Array,
        },
        levelFilter: {
            type: Object,
        },
        planningFilter: {
            type: String,
        },
        filteredSubjects: {
            type: Array,
        },
        subjectsFilter: {
            type: Array,
        },
        zipCodeFilter: {
            type: [String, Object],
        },
        allPeriods: {
            type: Array,
        },
        filteredPeriods: {
            type: Array,
        },
        periodFilter: {
            type: Object,
        },
        locationFilter: {
            type: Array,
        },
        selectedCourses: {
            type: Array,
        },
        getDetailById: {
            type: Function,
        },
        readableLevel: {
            type: Function,
        },
        landing: {
            type: Object,
            required: true
        },
    },

    data () {
        return {
            observer: null,
            courseElementInViewport: null,
            courseElementWidth: null,
            checkedVariantsIds: {}
        }
    },

    watch: {
        matchingCourses: {
            handler: function () {
                this.matchingCourses.forEach((group) => {
                    if (!this.checkedVariantsIds[group[0].id]) {
                        this.$set(this.checkedVariantsIds, group[0].id, group[0].id)
                    }
                })
            },

            immediate: true
        },

        coursesLoading: {
            handler: function (val, oldVal) {
                if (!val && oldVal) {
                    this.$nextTick(() => {
                        this.courseElementWidth = this.$el.querySelector('.one-course')?.clientWidth
                    })
                }
            },

            immediate: true
        },
    },

    mounted() {
        this.$nextTick(() => {
            this.courseElementWidth = this.$el.querySelector('.one-course')?.clientWidth
        })

        this.selectedCourses.forEach(({course, group}) => {
            this.$set(this.checkedVariantsIds, group[0].id, course.id)
        })
    },

    methods: {
        renderMustache(str, context) {
            return render(str, context)
        },

        handleScroll() {
            const el = this.$el.querySelector('#observer-root')
            if (el.scrollWidth - el.clientWidth - el.scrollLeft < 500) {
                this.$emit('loadMore')
            }
        },

        handlePrevClick() {
            const parent = this.$el.querySelector('#observer-root')
            const scrollLeft = parent.scrollLeft

            if (scrollLeft) {
                parent.scrollBy({
                    top: 0,
                    left: -this.courseElementWidth,
                    behavior: 'smooth'
                })
            } else {
                const allCourses = this.$el.querySelectorAll('#observer-root .one-course')
                const last = allCourses[allCourses.length - 1]
                last.scrollIntoView({ behavior: 'smooth', inline:'center' })
            }
        },

        handleNextClick() {
            const parent = this.$el.querySelector('#observer-root')
            const scrollLeft = parent.scrollLeft
            const scrollWidth = parent.scrollWidth
            const clientWidth = parent.clientWidth

            if (scrollLeft + clientWidth < scrollWidth) {
                parent.scrollBy({
                    top: 0,
                    left: this.courseElementWidth,
                    behavior: 'smooth'
                })
            } else {
                const allCourses = this.$el.querySelectorAll('#observer-root .one-course')
                const first = allCourses[0]
                first.scrollIntoView({ behavior: 'smooth', inline:'center' })
            }
        },

        handleVariantChange(course, group) {
            this.$set(this.checkedVariantsIds, group[0].id, course.id)

            const isAlreadySelected = this.selectedCourses.find((selectedCourse) => selectedCourse.group[0].id === group[0].id)
            if (isAlreadySelected) {
                this.$emit('selectedVariantChange', course, group)
            }
        },

        handleSelectCourseClick(group, courseId) {
            const course = group.find((c) => c.id === courseId)
            this.$emit('selectCourseClick', group, course)
        },

        handleRemoveCourseClick(group) {
            this.$delete(this.checkedVariantsIds, group[0].id)
            this.$emit('removeCourseClick', group[0].id)
        }
    },

}
</script>

<template>
    <div class="home-screen mt-6 lg:mt-0">
        <div class="flex flex-wrap pb-3" v-if="landing.filters">
            <div v-for="filter in landing.filters" :class="filter.classname" class="basis-full" :key="filter.name">
                <template v-if="filter.type === 'location'">
                    <div class="flex flex-wrap ">
                        <div v-for="key in Object.keys(filter.values)"
                          :key="key"
                          class="px-5 min-h-5xl py-2 items-center mb-2 rounded-md inline-flex space-x-3 basis-full lg:basis-auto lg:mr-3 flex-wrap"
                          :class="{ 'bg-coral' : key === 'c', 'bg-yellow' : key === 'l' }"
                          @click="$emit('locationFilterChange', key, filter.values, $event)">
                            <BaseCheckbox @click.prevent.stop
                              :checked="locationFilter.includes(key)"/>
                            <span class="font-bold">{{ filter.values[key] }}</span>
                            <BaseZipcode v-if="key === 'c' " class="zipcode" style="flex-grow:1; max-width:350px;"
                              @click.prevent.stop="$emit('locationFilterAdd', key, filter.values, $event)"
                              placeholder="Votre ville ou code postal"
                              :value="zipCodeFilter"
                              @valueChange="$emit('zipCodeFilterChange', $event)"> </BaseZipcode>
                        </div>
                    </div>
                </template>
                <template v-if="filter.type === 'level'">
                    <BaseMultiSelect :key="JSON.stringify(filteredLevels)"
                      :options="[...filteredLevels].sort((a, b) => b.value - a.value).map((level) => ({ ...level, name: readableLevel(level.name) }))"
                      :placeholder="filter.name"
                      :searchable="false"
                      :closeOnSelect="true"
                      :value="levelFilter ? filteredLevels.find((filter) => filter.value === levelFilter.value) : null"
                      track-by="value"
                      label="name"
                      :showLabels="false"
                      :hideSelected="false"
                      :multiple="false"
                      @valueChange="$emit('levelChange', $event)">
                        <template slot="singleLabel" slot-scope="{ option }">
                            {{ readableLevel(option.name) }}
                        </template>
                        <template slot="option" slot-scope="{ option }">
                            <div class="flex items-center justify-between">
                                {{ option.name }}
                            </div>
                        </template>
                        <div slot="after"
                          @click="$emit('levelReset')"
                          :class="{ hidden: !levelFilter }"
                          class="ml-4 cursor-pointer">
                            <IconCross style="width: 12px;" />
                        </div>
                    </BaseMultiSelect>
                </template>
                <template v-if="filter.type === 'subjects'">
                    <BaseMultiSelect :key="JSON.stringify(filteredSubjects)"
                      :options="[...filteredSubjects].sort((a, b) => a.name.localeCompare(b.name))"
                      :placeholder="filter.name"
                      :searchable="false"
                      :closeOnSelect="false"
                      :value="subjectsFilter"
                      track-by="name"
                      label="name"
                      :showLabels="false"
                      :hideSelected="false"
                      :multiple="true"
                      @valueChange="$emit('subjectsChange', $event)">
                        <template slot="selection" slot-scope="{ values }">
                            <span class="multiselect__single" v-if="values.length === 1">
                                {{ values[0].name }}
                            </span>
                            <span class="multiselect__single" v-else-if="values.length > 1">
                                {{ values.length }}
                                {{ $filters.plural('matière', values.length) }}
                                {{ $filters.plural('sélectionnée', values.length) }}
                            </span>
                        </template>
                        <template slot="option" slot-scope="{ option }">
                            <div class="flex items-center justify-between">
                                {{ option.name }}
                                <BaseCheckbox class="no-label"
                                  :checked="!!subjectsFilter.find((filter) => filter.name === option.name)">
                                </BaseCheckbox>
                            </div>
                        </template>
                        <div slot="after"
                          @click="$emit('subjectsReset')"
                          :class="{ hidden: !subjectsFilter || !subjectsFilter.length }"
                          class="ml-4 cursor-pointer">
                            <IconCross style="width: 12px;" />
                        </div>
                    </BaseMultiSelect>
                </template>
                <template v-if="filter.type === 'period'">
                    <BaseMultiSelect :key="JSON.stringify(allPeriods)"
                      :options="allPeriods"
                      :placeholder="filter.name"
                      :value="periodFilter ? allPeriods.find((filter) => filter.value === periodFilter.value) : null"
                      :searchable="false"
                      :closeOnSelect="true"
                      track-by="value"
                      label="name"
                      :showLabels="false"
                      :hideSelected="false"
                      :multiple="false"
                      @valueChange="$emit('periodsChange', $event)"
                    >
                        <template slot="singleLabel" slot-scope="{ option }">
                            {{ option.name }}
                        </template>
                        <template slot="option" slot-scope="{ option }">
                            <div class="flex items-center justify-between">
                                {{ option.name }}
                            </div>
                        </template>
                        <div slot="after"
                          @click="$emit('periodsReset')"
                          :class="{ hidden: !periodFilter }"
                          class="ml-4 cursor-pointer">
                            <IconCross style="width: 12px;" />
                        </div>
                    </BaseMultiSelect>
                </template>
                <template v-if="filter.type === 'planning'">
                    <BaseMultiSelect :key="JSON.stringify(filter.values)"
                      :options="Object.keys(filter.values)"
                      :placeholder="filter.name"
                      :searchable="false"
                      :closeOnSelect="true"
                      :value="planningFilter"
                      :showLabels="false"
                      :hideSelected="false"
                      :multiple="false"
                      @valueChange="$emit('planningChange', $event)">
                        <template slot="singleLabel" slot-scope="{ option }">
                            {{ filter.values[option] }}
                        </template>
                        <template slot="option" slot-scope="{ option }">
                            <div class="flex items-center justify-between">
                                {{ filter.values[option] }}
                            </div>
                        </template>
                    </BaseMultiSelect>
                </template>
            </div>
        </div>

        <div class="flex lg:pb-3 lg:items-center flex-col lg:flex-row items-start space-y-5 lg:space-y-0 lg:space-x-8 ">
            <div class="" v-if="matchingCoursesLength > 0">
                <template v-if="isStage">
                    Nous avons <strong>{{ matchingCoursesLength }} {{ $filters.plural('stage', matchingCoursesLength) }}</strong>
                </template>
                <template v-else>
                    Nous avons <strong>{{ matchingCoursesLength }} cours</strong>
                </template>
            </div>
        </div>

        <div v-if="coursesLoading" class="pb-5">
            <div class="flex justify-center items-center py-5">
                <BaseLoader class="w-16"/>
            </div>
        </div>
        <div v-else-if="!matchingCoursesLength" class="pb-5 ">
            <div class="leading-7" style="max-width: 650px;" v-html="renderMustache(landing.error_message, { model: model })">
            </div>
        </div>
        <div v-else ref="scrollWrapper" class="relative pb-5">
            <div
              class="z-10 hidden gradient-left w-14 cursor-pointer lg:inline-flex absolute top-0 bottom-10 left-0"
              @click="handlePrevClick">
                <IconArrowheadLeft  style="width: 60px" />
            </div>
            <div
              class="z-10 hidden gradient-right w-14 cursor-pointer lg:inline-flex absolute top-0 bottom-10 right-0 flex-row-reverse"
              @click="handleNextClick">
                <IconArrowheadRight style="width: 60px" />
            </div>
            <div class="flex pb-1 justify-center space-x-8 lg:hidden pt-5 " >
                <div
                  class="cursor-pointer inline-flex rounded-full bg-black text-white justify-center items-center"
                  :style="{ width: '33px', height: '33px'}"
                  @click="handlePrevClick">
                    <IconArrowLeft style="width: 16px" />
                </div>
                <BaseButton v-if="false && selectedCourses.length" class="ml-2 base-button-solid" @click="$emit('bookClick')">
                    Je pré-réserve
                </BaseButton>
                <div
                  class="cursor-pointer inline-flex rounded-full bg-black text-white justify-center items-center"
                  :style="{ width: '33px', height: '33px'}"
                  @click="handleNextClick">
                    <IconArrowRight style="width: 16px" />
                </div>
            </div>
            <div id="observer-root" @scroll="handleScroll" class="pb-5 pt-5 overflow-auto">
                <div class="flex space-x-6 desktop-pseudo-paddding">
                    <CourseCard v-for="group in matchingCourses"
                      :group="group"
                      :key="group[0].id"
                      :checkedVariantId="checkedVariantsIds[group[0].id]"
                      :class="{
                        active: selectedCourses.find((selectedCourse) => selectedCourse.group[0].id === group[0].id),
                        'selected-label': selectedCourses.find((selectedCourse) => selectedCourse.group[0].id === group[0].id),
                      }"
                      class="one-course w-3/4 lg:w-1/2"
                      :isStage="isStage"
                      :detail="getDetailById(group[0].produitId)"
                      @courseProgramClick="(group, course) => $emit('courseProgramClick', group, course)"
                      @variantChange="handleVariantChange">
                        <template #bottom="{ checkedVariantId }">
                            <BaseButton v-if="selectedCourses.find((selectedCourse) => selectedCourse.group[0].id === group[0].id)"
                              @click.native="handleRemoveCourseClick(group)"
                              class="mb-4 base-button-color font-semibold px-8">
                                <span>Déselectionner</span>
                            </BaseButton>
                            <BaseButton v-else @click.native="handleSelectCourseClick(group, checkedVariantId)"
                              class="mb-4 base-button-outline px-8 font-semibold">
                                <span>Je sélectionne</span>
                            </BaseButton>
                        </template>
                    </CourseCard>
                </div>
            </div>

        </div>
    </div>
</template>

<style lang="stylus">
.home-screen
    #observer-root
        scroll-snap-type x mandatory
        scrollbar-width thin
        scrollbar-color rgba(0, 0, 0, .6) rgba(0, 0, 0, 0.3)

        &:hover
            scrollbar-color: rgba(0, 0, 0, .6) rgba(0, 0, 0, 0.3)

        &::-webkit-scrollbar
            width 6px
            height 6px

        &::-webkit-scrollbar-track
            transition background ease .2s
            background rgba(0, 0, 0, 0.3)

        &::-webkit-scrollbar-thumb
            transition background-color ease .2s
            border-radius 6px
            background-color rgba(0, 0, 0, 0.6)

        &:hover::-webkit-scrollbar-thumb
            background-color rgba(0, 0, 0, 0.6)

        .one-course
            scroll-snap-align center
            box-shadow 0px 0px 3px 3px rgba(0, 0, 0, 0.07)

    @media (min-width: 1024px)
        .desktop-pseudo-paddding:before
        .desktop-pseudo-paddding:after
            content ''
            display block
            width 60px
            flex-shrink 0

</style>