<script>
export default {
    name: 'BaseChecklist',

    props: {
        values: {
            type: Array,
        },

        options: {
            type: Array,
        },
    },

    data() {
        return {
            uid: undefined,
            isTouchDevice: undefined
        }
    },

    mounted() {
        const inBrowser = typeof window !== 'undefined'
        if (inBrowser) {
            this.uid = 'checkbox' + Math.random()
            this.isTouchDevice = 'ontouchstart' in document.documentElement
        }
    },
}
</script>

<template>
    <div class="base-checklist" :class="{ 'not-touch-device': !isTouchDevice }">
        <div class="before">
            <slot name="before" />
        </div>
        <label
            v-for="(option, i) in options"
            :key="option.name"
            :id="option.id"
            :class="[{ 'is-checked': values && values.includes(option.value) }, option.classname]"
        >
            <input
                type="checkbox"
                v-on="$listeners"
                :value="option.value"
                :id="`${uid}_${i}`"
                v-bind="$attrs"
                :checked="values && values.includes(option.value)"
            />
            <div class="label-text">
                <slot name="label" :option="option">
                    {{ option.name }}
                </slot>
            </div>
        </label>
        <div class="after">
            <slot name="after" />
        </div>
    </div>
</template>

<style lang="stylus">
@import '../assets/stylus/vars.styl'

.base-checklist
    
    & > label + label
        margin-top 16px

    .before:empty
    .after:empty
        display none

    label
        transition background-color ease .3s
        background-color $AkWhite
        border-radius 10px
        display flex
        align-items center
        min-height 64px
        padding-left 16px
        padding-right 16px
        flex-direction row-reverse
        justify-content space-between
        cursor pointer
        border 1px solid var(--color3)
        border-radius 10px

        &.is-checked
            background-color var(--color3)
            border-color var(--color3)

            .label-text
                color var(--color3Inverse)

    input
        margin 0
        padding 0

    .label-text
        font-size 14px
        color $AkGreyscaleBlack

    &.not-touch-device label:hover
        background-color var(--color3)
        border-color var(--color3)

        .label-text
            color var(--color3Inverse)

</style>
