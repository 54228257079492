<script>
export default {
    name: 'BaseButton',

    props: {
        disabled: Boolean,
    },

    data() {
        return {
            isTouchDevice: undefined
        }
    },

    mounted() {
        const inBrowser = typeof window !== 'undefined'
        if (inBrowser) {
            this.isTouchDevice = 'ontouchstart' in document.documentElement
        }
    },
}
</script>
<template>
    <Component :is="$attrs.href ? 'a' : 'div'"
        class="base-button"
        :class="{ disabled, 'not-touch-device': !isTouchDevice }"
        v-on="$listeners"
        v-bind="$attrs"
        role="button"
    >
        <slot></slot>
    </Component>
</template>
<style lang="stylus">
@import '../assets/stylus/vars.styl'
.base-button
    transition ease .2s color, ease .2s background-color, ease .2s border-color
    border 1px solid transparent
    border-radius 9999px
    font-weight 500
    cursor pointer
    height 3em
    padding-left 3em
    padding-right 3em
    display inline-flex
    align-items center
    justify-content center
    text-decoration none

    &.base-button-solid
        color var(--color5Inverse)
        background-color var(--color5)
        border-color var(--color5)

        &.not-touch-device:hover
        &:focus
        &.hover
        &.focus
            color var(--color6Inverse)
            background-color var(--color6)
            border-color var(--color6)

        &.disabled
            color var(--AkWhite)
            background-color var(--AkGreyscaleMediumGrey)
            border-color var(--AkGreyscaleMediumGrey)

    &.base-button-outline
        color var(--color5)
        background-color transparent
        border-color var(--color5)

        &.not-touch-device:hover
        &:focus
        &.hover
        &.focus
            color var(--color6Inverse)
            background-color var(--color6)
            border-color var(--color6)

        &.disabled
            color var(--AkGreyscaleMediumGrey)
            border-color var(--AkGreyscaleMediumGrey)

    &.base-button-color
        color var(--color1Inverse)
        background-color var(--color1)
        border-color var(--color1)

        &.not-touch-device:hover
        &:focus
        &.hover
        &.focus
            color var(--colorMinorInverse)
            background-color var(--colorMinor)
            border-color var(--colorMinor)

        &.disabled
            color var(--AkWhite)
            background-color var(--AkGreyscaleMediumGrey)
            border-color var(--AkGreyscaleMediumGrey)

    &.base-button-reverse
        color var(--color5)
        background-color #fff
        border-color transparent

        &.not-touch-device:hover
        &:focus
        &.hover
        &.focus
            color var(--color6Inverse)
            background-color var(--color6)
            border-color var(--color6)

        &.disabled
            color var(--AkWhite)
            background-color var(--AkGreyscaleMediumGrey)
            border-color var(--AkGreyscaleMediumGrey)


    &.base-button-small
        font-size 0.8em

    &.disabled
        cursor default
        pointer-events none
</style>
