<script>
import Multiselect from 'vue-multiselect'
import IconArrowheadDown from '@/components/icons/IconArrowheadDown'
import IconArrowheadTop from '@/components/icons/IconArrowheadTop'
import BaseCheckbox from '@/components/BaseCheckbox'

export default {
    name: 'BaseMultiSelect',

    inheritAttrs: false,

    props: {
        value: {
            type: [Object, Array, String, Number],
        },

        options: {
            type: Array,
            default: () => []
        },

        endpoint: {
            type: String,
        },
    },

    components: {
        Multiselect,
        IconArrowheadDown,
        IconArrowheadTop,
        BaseCheckbox,
    },

    data() {
        return {
            focused: false,
            loading: false,
            uid: undefined,
            choices: []
        }
    },

    mounted() {
        const inBrowser = typeof window !== 'undefined'
        if (inBrowser) {
            this.uid = this.$attrs.id ? this.$attrs.id : 'multi-select' + Math.random()
        }

        if (this.endpoint) {
            this.choices = []
            this.handleSearchChange()
        } else {
            this.choices = this.options
        }
    },

    methods: {
        plural(value, count) {
            if (count > 1) {
                return value + 's'
            }

            return value
        },

        handleInput(value) {
            this.$emit('valueChange', value)
        },

        getChoices() {
            return fetch(`${this.endpoint}`, { headers: {} })
                .then((response) => response.json())
                .then((json) => json)
        },

        handleSearchChange (query) {
            if (!this.endpoint) {
                return
            }

            this.loading = true
            this.getChoices(query).then(response => {
                this.loading = false

                if (response.data) {
                    this.choices = response.data
                } else {
                    this.choices = response
                }
            })
        },
    },
}
</script>

<template>
    <div
        class="base-multi-select"
        :class="{
            ...$attrs.class,
            'has-value': value && value.length,
            focused: focused,
        }"
    >
        <label :for="`${uid}`">
            <div class="label-text">
                <slot name="label" />
            </div>
        </label>
        <div class="select-wrapper">
            <div class="before">
                <slot name="before" />
            </div>
            <Multiselect ref="multiselect"
              v-bind="$attrs"
              @input="handleInput"
              @open="focused = true"
              @close="focused = false"
              @search-change="handleSearchChange"
              :closeOnSelect="$attrs.closeOnSelect === undefined ? false : $attrs.closeOnSelect"
              :hideSelected="$attrs.hideSelected === undefined ? false : $attrs.hideSelected"
              :showLabels="$attrs.showLabels === undefined ? false : $attrs.showLabels"
              :loading="loading"
              :value="value"
              :options="choices">
                <template slot="selection" slot-scope="scope">
                    <template v-if="!!$scopedSlots.selection">
                        <slot name="selection" v-bind="scope" />
                    </template>
                    <template v-else>
                        <span class="multiselect__single" v-if="scope.values.length === 1">
                            <span class="multiselect__tag">
                                <span>{{ $refs.multiselect.getOptionLabel(scope.values[0]) }}</span>
                                <i aria-hidden="true" tabindex="1" class="multiselect__tag-icon" @click="$refs.multiselect.removeElement(scope.values[0])"></i>
                            </span>

                        </span>
                        <span class="multiselect__many" v-else-if="scope.values.length > 1">
                            {{ scope.values.length }}
                            {{ plural('élément', scope.values.length) }}
                            {{ plural('sélectionné', scope.values.length) }}
                        </span>
                    </template>
                </template>
                <template slot="beforeList" slot-scope="scope">
                    <slot name="beforeList" v-bind="scope" />
                </template>
                <template slot="afterList" slot-scope="scope">
                    <slot name="afterList" v-bind="scope" />
                </template>
                <template slot="singleLabel" slot-scope="scope">
                    <slot name="singleLabel" v-bind="scope" />
                </template>
                <template slot="placeholder" slot-scope="scope">
                    <slot name="placeholder" v-bind="scope" />
                </template>
                <template slot="tag" slot-scope="scope">
                    <slot name="tag" v-bind="scope" />
                </template>
                <template  slot="option" slot-scope="scope">
                    <template v-if="!!$scopedSlots.option">
                        <slot name="option" v-bind="scope" />
                    </template>
                    <template v-else>
                        <div class="base-multi-select-option">
                            {{ $refs.multiselect.getOptionLabel(scope.option) }}
                            <BaseCheckbox class="no-label" v-if="$refs.multiselect.multiple"
                              :checked="!!($refs.multiselect.getValue() || []).find((choice) => choice[$refs.multiselect.trackBy] === scope.option[$refs.multiselect.trackBy])">
                            </BaseCheckbox>
                        </div>
                    </template>
                </template>
                <template
                  slot="caret"
                  slot-scope="{ toggle }">
                    <IconArrowheadTop
                      v-if="focused"
                      width="12"
                      class="arrow"
                      @mousedown.native.prevent.stop="toggle" />
                    <IconArrowheadDown
                      v-else
                      width="12"
                      class="arrow"
                      @mousedown.native.prevent.stop="toggle" />
                </template>
                <span slot="noResult">Aucun résultat.</span>
            </Multiselect>

            <div class="after">
                <slot name="after" />
            </div>
        </div>
    </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="stylus">
@import '../assets/stylus/vars.styl'

.base-multi-select
    position relative
    font-size 1.4em

    label
        color $AkBlack
        display block
        padding-bottom 5px
        padding-left 28px

    .select-wrapper
        display flex
        color $AkGreyscaleDarkGrey
        background-color #fff
        border-radius 120px
        padding-left 2em
        padding-right 24px
        border 1px solid var(--color3)
        font-size 16px
        min-height 3em
        align-items center

        input
            color var(--AkGreyscaleBlack)
            font-family inherit
            font-size inherit
            margin-top 2px

            &::placeholder
                transition color ease 0s
                color var(--AkGreyscaleMediumGrey)

        .base-multi-select-option
            display flex
            align-items center
            justify-content space-between

            .base-checkbox.no-label
                label
                    display none

        .multiselect__single
        .multiselect__many
            padding-left 4px

        .multiselect__single
            margin-bottom 0

        .multiselect__many
            padding-top 3px
            display inline-block

        .multiselect__tag
            margin-bottom 0

        .multiselect__content-wrapper
            border 1px solid #e8e8e8
            border-radius 10px
            margin-top 5px

        .multiselect__tags
            border 0
            padding-left 0
            font-size 16px

        .multiselect__placeholder
            margin-left 5px
            color var(--AkGreyscaleMediumGrey)
            font-weight normal
            font-size 16px

        .multiselect__tag
            background var(--color3)
            color var(--color3Inverse)

        .multiselect__tag-icon:hover
            background var(--color3)
            color var(--color3Inverse)

        .multiselect__option--highlight
            background var(--color3)
            color var(--color3Inverse)

        .arrow
            position absolute
            right 0
            top 12px

        .after
        .before
            display flex

    &.focused
        border-color var(--color5)

    &.has-errors:not(.focused)
        .select-wrapper
            border-color $AkMajorCoral

        .label-text
            color $AkMajorCoral

        .after
        .before
            color $AkMajorCoral

    &.is-success:not(.focused)
        .select-wrapper
            border-color $AkMainGreen

        .label-text
            color $AkMainGreen

        .after
        .before
            color $AkMainGreen

    &.base-multi-select-small
        font-size 1.15em

        .label-text
            font-size .9em
</style>
