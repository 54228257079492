<script>
export default {
    name: 'BaseSwitch',

    inheritAttrs: false,

    props: {
        value: {
            type: Boolean,
            default: false,
        },
    },
}
</script>

<template>
    <div class="base-switch" :class="{
        ...$attrs.class,
        'is-checked': value
    }">
        <div class="foreground"></div>
        <input
            type="checkbox"
            v-on="$listeners"
            v-bind="$attrs"
            :checked="value"
        />
    </div>
</template>

<style lang="stylus">
@import '../assets/stylus/vars.styl'

.base-switch
    transition background .3s ease
    height 24px
    width 48px
    border-radius 12px
    background $AkGreyscaleMediumLtGrey
    position relative
    cursor pointer

    input
        opacity 0
        position absolute
        top 0
        left 0
        width 100%
        height 100%
        padding 0
        margin 0
        cursor pointer

    &.is-checked
        background var(--color3)

        .foreground
            left 100%
            transform translateX(calc(-100% - 4px))

    .foreground
        transition left .3s ease, transform .3s ease
        background #FFF
        position absolute
        transform translateX(4px)
        top 4px
        bottom 4px
        left 0
        width 16px
        border-radius 13px
</style>
