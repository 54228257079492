<script>

import {
    BaseCheckbox,
    IconAddress,
    IconClock,
    IconCross,
    IconCalendarCheckLight,
    IconCoinEuroLight,
    IconDoc,
} from 'aca-design'

import PopperJS from '@/components/PopperJS.vue'
import AgencyAddress from '@/components/AgencyAddress.vue'

export default {
    name: 'CourseCard',

    components: {
        PopperJS,
        IconAddress,
        IconClock,
        IconCross,
        IconCalendarCheckLight,
        IconCoinEuroLight,
        IconDoc,
        AgencyAddress,
        BaseCheckbox
    },

    props: {
        group: {
            type: Array,
            required: true,
        },

        collapsed: {
            type: Boolean,
            default: false
        },

        checkedVariantId: {
            type: String
        },

        detail: {
            type: Object
        },

        isStage: {
            type: Boolean,
            default: true
        },
    },

    data () {
        return {
            showAddress: false,
            isMobile: window.matchMedia('(max-width: 800px)').matches
        }
    },

    computed: {
        course() {
            return this.group[0]
        }
    },

    methods: {
        readableLevel(level) {
            if (level === '1ère') {
                return '1re'
            }
            if (level === '2nd') {
                return '2de'
            }
            if (level === '3ème') {
                return '3e'
            }
            if (level === '4ème') {
                return '4e'
            }
            if (level === '5ème') {
                return '5e'
            }
            if (level === '6ème') {
                return '6e'
            }

            return level
        },

        readableDate(dateString) {
          const [day, month, year] = dateString.split('/')
          const date = new Date(year, month - 1, day)
          const options = { month: 'long', day: 'numeric' }
          return date.toLocaleDateString('fr-FR', options)
        }
    },
}
</script>

<template>
    <div v-if="collapsed"
      class="course-card shrink-0 grow-0 bg-white px-5 pb-0 rounded-2xl inline-block relative min-h-[78px] flex items-center">
        <h4 class="font-semibold text-md pr-2">{{ course.produitDesc }}</h4>
        <div class="absolute top-5 right-3">
            <slot name="top-right"></slot>
        </div>
    </div>
    <div v-else
      class="course-card shrink-0 grow-0 bg-white p-5 pb-0 rounded-2xl inline-block relative">
        <div class="lg:mb-6 mb-5 flex lg:items-center flex-col lg:flex-row" >
            <span v-if="course.enLigne" class="tag-ligne w-max">En ligne</span>
            <span v-else class="tag w-max">En centre</span>
            <PopperJS v-if="!course.enLigne"
                ref="popper"
                trigger="clickToOpen"
                :append-to-body="true"
                @show="showAddress = true"
                @hide="showAddress = false"
                :options="{
                  placement: 'right',
                  strategy: 'fixed',
                  modifiers: {
                    offset: {
                        offset: isMobile ? '0, 0' : '0, 10px',
                    },
                    preventOverflow: {
                        boundariesElement: 'viewport'
                    }
                  }
                }">
                <div :style="`max-width: ${isMobile ? 'none': '350px'};`" class="z-10 w-full">
                    <AgencyAddress v-if="showAddress"
                      @loading="$refs.popper.updatePopper()"
                      @loaded="$refs.popper.updatePopper()"
                      class="lg:p-5 shadow-2xl"
                      :agencyId="course.agenceId"
                      :showMap="false"
                    >
                        <template #top-right>
                            <IconCross
                              class="cursor-pointer"
                              @click.native="$refs.popper.doClose()" />
                        </template>
                    </AgencyAddress>
                </div>

                <span slot="reference" class="cursor-pointer flex font-bold text-lg lg:mt-0 mt-5">
                    <IconAddress class="lg:ml-6 mr-1"/> {{course.agenceLib}}
                </span>
            </PopperJS>
        </div>
        <div class="absolute top-5 right-3">
            <slot name="top-right"></slot>
        </div>

        <h4 class="font-semibold text-xl lg:text-2xl pr-2">{{ course.produitDesc }}</h4>
        <p class="level lg:mt-2 mt-5 lg:mb-6 mb-5 font-semibold">{{ readableLevel(course.niveauLib) }}</p>
        <div class="lg:my-4 my-5 flex items-center font-bold">
            <template v-if="!isStage">
                <IconCalendarCheckLight  class="mr-2 shrink-0" /> Toute l'année (hors vacances scolaires)
            </template>
            <template v-else-if="course.dateDebut === course.dateFin">
                <IconCalendarCheckLight  class="mr-2 shrink-0" /> Le {{ readableDate(course.dateDebut) }}
            </template>
            <template v-else>
                <IconCalendarCheckLight  class="mr-2 shrink-0" /> Du {{ readableDate(course.dateDebut) }} au {{ readableDate(course.dateFin) }}
            </template>
        </div>
        <div class="lg:my-4 my-5 flex items-center font-bold">
            <template v-if="isStage">
                <IconCoinEuroLight class="mr-2 shrink-0" />{{ course.tarif }} €
            </template>
            <template v-else-if="course.produitDesc.includes('Aide aux devoirs') && !course.enLigne && ['CP', 'CE1', 'CE2', 'CM1', 'CM2'].includes(course.niveauLib)">
                <IconCoinEuroLight class="mr-2 shrink-0" />Forfait à partir de {{ course.tarif }} € / mois
            </template>
            <template v-else>
                <IconCoinEuroLight class="mr-2 shrink-0" />Forfait {{ course.tarif }} € / mois
            </template>
        </div>
        <div v-if="detail" class="lg:my-4 my-5 flex items-center">
            <IconDoc class="mr-2 shrink-0 inline-block " />
            <a href="#"
              @click.prevent="$emit('courseProgramClick', group, group[0])"
              class="underline-link font-bold">
                 Voir le détail du stage
            </a>
        </div>
        <template v-if="group.length > 1">
            <div class="lg:mt-4 mt-5 flex items-center">
                <IconClock class="mr-2 shrink-0" style=" align-self: flex-start; margin-top: 2px; "/> Choisissez votre créneau de préférence
            </div>
            <div class="lg:mt-2 mt-3 lg:mb-4 mb-5 pl-6">
                <div v-for="item in group" :key="item.id" class="flex items-center mb-2 font-bold">
                    <BaseCheckbox class="base-checkbox-round"
                      :checked="checkedVariantId === item.id"
                      @change="$emit('variantChange', item, group)">
                        <div slot="label">
                            <template v-if="item.heureDebut === 'Journée'">
                                Journée
                            </template>
                            <template v-else>
                                <template v-if="item.jourDebut"> {{ item.jourDebut }}</template>
                                {{ item.heureDebut }}&nbsp;-&nbsp;{{ item.heureFin }}
                            </template>
                        </div>
                        <div slot="indicator" class="indicator-dot"></div>
                    </BaseCheckbox>
                </div>
            </div>
        </template>
        <template v-else>
            <div class="lg:mt-4 mt-5 mb-3 font-bold flex items-center">
                <IconClock class="mr-2 shrink-0" style=" align-self: flex-start; margin-top: 2px; "/>
                <template v-if="group[0].heureDebut === 'Journée'">
                    Journée
                </template>
                <template v-else>
                    <template v-if="group[0].jourDebut"> {{ group[0].jourDebut }}</template>
                    {{ group[0].heureDebut }}&nbsp;-&nbsp;{{ group[0].heureFin }}
                </template>
            </div>
        </template>


        <div class="flex items-center flex-wrap justify-between">
            <slot name="bottom" :checkedVariantId="checkedVariantId"></slot>
        </div>
    </div>
</template>

<style lang="stylus">
</style>
