<script>
import loadGoogleMapsApi from 'load-google-maps-api'
import { typesRepetition, typesRoute } from '../AddressEnums'

function normalize(text) {
    const noAccent = text.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
    return noAccent.replace(/\s\s+/g, ' ').toLowerCase().trim()
}

function extractRepetition(numberAndRepetition) {
    let number = ''
    let repetition = ''

    ;[...numberAndRepetition].forEach((c) => {
        if (/\d/.test(c) && !repetition.length) {
            number += c
        } else {
            repetition += c
        }
    })

    return {
        number: number.trim(),
        repetition: repetition.trim(),
    }
}

function slugify(text) {
    if (!text) {
        return ''
    }
    return (
        text
            .toString()
            // split an accented letter in the base letter and the acent
            .normalize('NFD')
            // remove all previously split accents
            .replace(/[\u0300-\u036f]/g, '')
            .toLowerCase()
            .trim()
            .replace(/\s+/g, '-')
            // eslint-disable-next-line no-useless-escape
            .replace(/[^\w\-]+/g, '')
            // eslint-disable-next-line no-useless-escape
            .replace(/\-\-+/g, '-')
    )
}

export default {
    name: 'BaseAddress',

    inheritAttrs: false,

    props: {
        value: {
            type: [String, Object],
        },

        types: {
            type: Array,
            default: () => [],
        },

        restrictions: {
            type: Object,
            default: () => {
                return {
                    country: ['fr', 're'],
                }
            },
        },
    },

    data() {
        return {
            autocomplete: undefined,
            focused: false,
            uid: undefined,
            loading: false,
        }
    },

    computed: {
        displayableValue() {
            if (typeof this.value === 'object') {
                return `${this.value.rawGooglePlace.formatted_address}`
            } else {
                return this.value
            }
        }
    },

    mounted() {
        loadGoogleMapsApi({
            key: 'AIzaSyCWsbZxeOvV8ZjBUglp2xumatSzuXCpANQ',
            libraries: ['places'],
        }).then((googleMaps) => {
            const input = this.$refs['searchTextField']
            const options = {
                types: this.types,
            }

            this.autocomplete = new googleMaps.places.Autocomplete(
                input,
                options
            )

            this.autocomplete.setComponentRestrictions(this.restrictions)

            this.autocomplete.setFields([
                'address_components',
                'formatted_address',
            ])

            googleMaps.event.addListener(
                this.autocomplete,
                'place_changed',
                async () => {
                    const place = this.autocomplete.getPlace()

                    let parsedComponents = this.parseComponents(
                        place.address_components
                    )

                    const { cpCommunes } = await this.fetchCities(
                        parsedComponents.googleZipcode ||
                            parsedComponents.googleCityName
                    )

                    const city = this.getBestCity(
                        cpCommunes,
                        parsedComponents.googleCityName
                    )

                    parsedComponents.city = city
                    parsedComponents.rawGooglePlace = place

                    this.$emit('input', {
                        currentTarget: {
                            value: place.formatted_address,
                        },
                    })
                    this.$emit('addressChange', parsedComponents)
                }
            )
        })

        const inBrowser = typeof window !== 'undefined'
        if (inBrowser) {
            this.uid = this.$attrs.id ? this.$attrs.id : 'address' + Math.random()
        }
    },

    methods: {
        fetchCities(input) {
            const endpoint = 'https://contact.acadomia.fr/api/adresse/rechercherCpCommune'

            return fetch(`${endpoint}/?cpCommune=${input}`)
                .then((response) => response.json())
                .then((json) => json)
        },

        getBestCity(cities, cityName) {
            if (cities.length === 1) {
                return cities[0]
            }

            return cities.find((city) => {
                return slugify(city.communeLib) === slugify(cityName)
            })
        },

        parseComponents(googleComponents) {
            if (!googleComponents) {
                return undefined
            }

            const components = {}

            // repetition
            const streetNumberRaw = googleComponents.find((component) => {
                return component.types.includes('street_number')
            })

            if (streetNumberRaw) {
                const streetNumberNormalized = normalize(
                    streetNumberRaw.short_name
                )

                const { number, repetition } = extractRepetition(
                    streetNumberNormalized
                )

                components.repetition = repetition
                components.repetitionCode = typesRepetition[repetition]
                components.number = number
            }

            // route type
            const routeRaw = googleComponents.find((component) => {
                return component.types.includes('route')
            })

            if (routeRaw) {
                const routeNormalized = normalize(routeRaw.long_name)

                components.routeType = routeNormalized.split(' ')[0]
                components.routeTypeCode = typesRoute[components.routeType]

                // route name
                components.routeName = routeNormalized
                    .replace(components.routeType, '')
                    .trim()
            }

            // city name
            const localityRaw = googleComponents.find((component) => {
                return component.types.includes('locality')
            })

            if (localityRaw) {
                components.googleCityName = localityRaw.long_name
            }

            // zipcode
            const postalCodeRaw = googleComponents.find((component) => {
                return component.types.includes('postal_code')
            })

            if (postalCodeRaw) {
                components.googleZipcode = postalCodeRaw.short_name
            }

            // country
            const countryRaw = googleComponents.find((component) => {
                return component.types.includes('country')
            })

            if (countryRaw) {
                components.country = countryRaw.long_name
            }

            return components
        },
    },
}
</script>

<template>
    <div
        class="base-address base-input"
        :class="{
            ...$attrs.class,
            'has-value': displayableValue && displayableValue.length,
            focused: focused,
        }"
    >
        <label :for="`${uid}`">
            <div class="label-text">
                <slot name="label" />
            </div>
        </label>
        <div class="input-wrapper">
            <div class="before">
                <slot name="before" />
            </div>
            <input
                ref="searchTextField"
                @focus="focused = true"
                @blur="focused = false"
                v-on="$listeners"
                :value="displayableValue"
                :id="`${uid}`"
                v-bind="$attrs"
            />
            <div class="after">
                <slot name="after" />
            </div>
        </div>
    </div>
</template>
<style lang="stylus">
@import '../assets/stylus/vars.styl'

.base-input.base-address
    font-size 1.4em
    display block
    position relative
    cursor text

    .label-text
    input
        // padding-left 1em
        // padding-right 1em

    label
        padding-left 2em
        cursor text
        z-index 1
        display block
        padding-bottom 3px

    .label-text
        display block
        // color $AkGreyscaleBlack
        color $AkBlack

    .input-wrapper
        box-sizing border-box
        display flex
        align-items center
        opacity 1
        border 1px solid var(--color3)
        border-radius 9999px
        background $AkWhite
        height 3em
        font-size inherit
        padding-left 2em
        padding-right 2em

        input
            flex-grow 1
            flex-shrink 1
            border 0
            min-width 0
            height 100%
            box-sizing border-box
            color var(--AkGreyscaleBlack)
            font-family inherit
            font-size inherit

            &::placeholder
                color var(--AkGreyscaleMediumGrey)
                font-weight normal
                font-size 16px

            &:focus
                outline 0

                &::placeholder
                    color var(--AkGreyscaleMediumGrey)

    &.focused
    &.has-value
        label
            z-index 0

        .label-text
            font-weight normal
            color var(--color5)

        .input-wrapper
            opacity 1

    &.focused
        border-color var(--color5)


    &.has-errors:not(.focused)
        .input-wrapper
            border-color $AkMajorCoral

        .label-text
            color $AkMajorCoral

        .after
        .before
            color $AkMajorCoral

    &.is-success:not(.focused)
        .input-wrapper
            border-color $AkMainGreen

        .label-text
            color $AkMainGreen

        .after
        .before
            color $AkMainGreen

    &.base-input-small
        font-size 1.15em
</style>
