<script>
import {
    BaseButton,
} from 'aca-design'

import AgencyAddress from '@/components/AgencyAddress.vue'
export default {
    name: 'CourseDetails',

    props: {
        group: {
            type: Object,
            required: true,
        },

        course: {
            type: Object,
            required: true,
        },

        details: {
            type: Object,
            required: true,
        }
    },

    components: {
        AgencyAddress,
        BaseButton
    },

    data () {
        return {
            agency: null
        }
    },

    computed: {

    },

    methods: {

    },
}
</script>

<template>
    <div class="course-details flex-col lg:flex-row flex lg:space-x-5 p-5">
        <div class="basis-full lg:basis-3/5 mb-4 lg:mr-8 lg:-mt-14">
            <h2 class="font-toronto-gothic lg:text-5xl text-3xl pb-5 lg:pb-4">{{ details['Nom du produit'] }}</h2>
            <p class="pb-5 lg:pb-10 text-lg lg:text-xl leading-relaxed whitespace-pre-wrap">
                {{ details['Caractéristique Format'] }}<br>
                {{ details['Caractéristiques Comment'] }}
            </p>

            <h3 class="pb-5 font-semibold text-2xl">Objectifs</h3>

            <div class="pb-5 lg:pb-10 leading-8 lg:leading-10 ml-3" v-html="details['Objectifs']"></div>

            <h3 class="pb-5 font-semibold text-2xl">Programme</h3>
            <div class="bg-white rounded-3xl p-5 lg:p-10">
                <template v-for="i in 5">
                    <template v-if="details['Programme séance ' + i]">
                        <div :key="i">
                            <div class="pb-2 font-bold note">{{ details['Titre Séance / Objet ' + i] }}</div>
                            <div class="pb-6 whitespace-pre-wrap">{{ details['Programme séance ' + i] }}</div>
                        </div>
                    </template>
                </template>
            </div>
            <div class="pt-5">
            <BaseButton
                  class="base-button-solid"
                  @click="$emit('bookCourse', group, course)">
                    <span>Je sélectionne ce stage</span>
                </BaseButton>
            </div>
        </div>
        <div  class="basis-full lg:basis-2/5 lg:ml-14">
            <AgencyAddress v-if="course.agenceId && !course.enLigne"
              :agencyId="course.agenceId"/>
        </div>
    </div>
</template>

<style lang="stylus">
</style>
