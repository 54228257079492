<script>
export default {
    name: 'BaseProgressBar',

    props: {
        titles: {
            type: [Array, Object]
        },

        currentStep: {
            type: [String, Number],
        },

        totalSteps: {
            type: [String, Number],
        },

        value: {
            type: [String, Number],
        },
    },

    methods: {
        getProgressPercent(currentStep, totalSteps) {
            const currentStepFloat = parseFloat(currentStep)
            const totalStepsFloat = parseFloat(totalSteps)
            const percent = (currentStepFloat-1)/(totalStepsFloat-1)
            return percent
        },


        handleProgressBarStepClick(step) {
            this.$emit('valueChange', step)
        }
    },
}
</script>

<template>
    <div class="base-progress-bar">
        <div class="progress-bar-background">
            <div
              class="progress-bar-foreground"
              :style="{
                width: getProgressPercent(currentStep, totalSteps) * 100 +'%'
                 }">
            </div>
        </div>
        <template v-for="step in parseInt(totalSteps)">
            <div v-if="parseInt(step) < Math.floor(currentStep)"
              class="progress-bar-step past"
              :key="step"
              @click="handleProgressBarStepClick(step)"
            >
                <span class="progress-bar-step-title" v-if="titles[step]">
                    {{ titles[step] }}
                </span>
            </div>
            <div v-else-if="parseInt(step) === Math.floor(currentStep)"
              class="progress-bar-step active"
              :key="step"
            >
                <span class="progress-bar-step-title" v-if="titles[step]">
                    {{ titles[step] }}
                </span>
            </div>
            <div v-else class="progress-bar-step" :key="step">
                <span class="progress-bar-step-title" v-if="titles[step]">
                    {{ titles[step] }}
                </span>
            </div>
        </template>
    </div>
</template>
<style lang="stylus">
@import '../assets/stylus/vars.styl'

.base-progress-bar
    max-width 80%
    display flex
    align-items center
    justify-content space-between
    position relative
    max-width 380px
    flex-grow 1
    margin-top 20px

    .progress-bar-background
        transition width 0.3s ease
        overflow hidden
        background transparent
        height 0
        border-bottom 1px dashed gray
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        right: 0;
        margin-left: 16px;
        margin-right: 16px;

    .progress-bar-foreground
        background transparent
        transition: width 0.3s ease;
        position: absolute;
        top: 0;
        left: 0;
        height: 8px;
        border-radius: 8px;

    .progress-bar-step
        background #CCCCCC
        border 0
        width 11px
        height 11px
        border-radius: 50%;
        z-index: 1;
        position: relative;

        .progress-bar-step-title
            color var(--AkGreyscaleBlack)
            position: absolute;
            top 100%
            transform translate(-50%, 60%)
            font-weight: 500;
            font-size: 12px;
            line-height: 15px;
            display: block;
            white-space: nowrap;

        &.active
            background var(--color1)
            border 8px solid var(--colorMinor)

            .progress-bar-step-title
                color var(--AkGreyscaleBlack)

        &.past
            background var(--color1)
            border 0
            cursor pointer

            .progress-bar-step-title
                color var(--AkGreyscaleBlack)
</style>
