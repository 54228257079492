<script>
import {
    BaseButton,
    IconCross,
    IconArrowheadTop,
    IconArrowheadDown,
} from 'aca-design'
import ContactForm from '@/components/ContactForm.vue'
import BaseLoader from '@/components/BaseLoader.vue'

import CourseCard from '@/components/CourseCard.vue'

function addOrRemove(array, value) {
    var index = array.indexOf(value)

    if (index === -1) {
        array.push(value)
    } else {
        array.splice(index, 1)
    }
}

export default {
    name: 'HomeScreen',

    props: {
        selectedCourses: {
            type: Array,
        },

        getDetailById: {
            type: Function,
        },

        landing: {
            type: Object,
            required: true
        },

        model: {
            type: Object,
            default() {
                return {}
            }
        },

        isStage: {
            type: Boolean,
            default: true
        },

        zipCodeFilter: {
            type: Object
        }
    },

    components: {
        CourseCard,
        ContactForm,
        BaseButton,
        BaseLoader,
        IconCross,
        IconArrowheadTop,
        IconArrowheadDown,
    },

    watch: {

    },

    data () {
        return {
            expandedCourseIds: [],
            contactFormCustomComponentLoaded: false,
            contactFormLoading: true,
        }
    },

    computed: {
        contactFormPrefilledModel() {
            const courses = this.selectedCourses.map(({course}) => course)

            const enLigne = [...new Set(courses.map((course) => course.enLigne))]

            const levelMax = courses.reduce((prev, current) => (prev.niveauId > current.niveauId) ? prev : current)

            const propPrefilledModel = this.model
            const prefilledModel = {
                ...propPrefilledModel,
                subject: courses.map((course) => `${course.matiereLib}`).join('; '),
                'level-a': { name: levelMax.niveauLib, value: levelMax.niveauLib },
                goal: courses.map((course) => {
                    const duration = course.heureDebut === 'Journée' ? 'toute la journée' : `de ${course.heureDebut} à ${course.heureFin}`
                    return `${course.produitdesc}, ${course.niveauLib}, ${course.dateDebut} au ${course.dateFin}, ${duration}, ${course.enLigne ? 'En ligne' : 'Centre ' + course.agenceLib} `
                }).join('; '),
                agencyId: null,
                zipcode: this.zipCodeFilter,
                enLigne: enLigne
            }
            return JSON.stringify(prefilledModel)
        },
    },

    async mounted() {
        this.lazyload()
        await customElements.whenDefined('landing-form')
        this.contactFormCustomComponentLoaded = true
    },

    methods: {
        lazyload() {
            var scriptTag = document.createElement('script')
            scriptTag.src = `${process.env.VUE_APP_FORM_COMPONENT_URL}`
            scriptTag.type = 'text/javascript'
            scriptTag.async = true
            var headTag = document.getElementsByTagName('head')[0]
            headTag.appendChild(scriptTag)
        },

        handleExpandCourseClick(id) {
            addOrRemove(this.expandedCourseIds, id)
        },

        handleVariantChange(course, group) {
            this.$emit('selectedVariantChange', course, group)
        },

        handleModelChanged({ model }) {
            this.$emit('modelChanged', model)
        }
    },
}
</script>

<template>
<div class="basket-screen flex lg:space-x-8 flex-col lg:flex-row">
    <div class="basis-full lg:basis-1/2">
        <BaseButton
          class="base-button-outline mb-5 font-bold px-6 hidden lg:inline-flex self-start"
          @click="$emit('addCourseClick')">
            + J'ajoute un stage
        </BaseButton>
        <div class="hidden lg:flex flex-col space-y-5">
            <CourseCard v-for="{ group, course } in selectedCourses"
              :group="group"
              :key="course.coursId"
              :checkedVariantId="course.id"
              :detail="getDetailById(group[0].produitId)"
              :isStage="isStage"
              @courseProgramClick="(group, course) => $emit('courseProgramClick', group, course)"
              @variantChange="handleVariantChange"
              class="lg:w-[580px] active">
                <template #top-right>
                    <IconCross class="w-6 cursor-pointer"
                      @click.native="$emit('removeCourseClick', group[0].id)"/>
                </template>
            </CourseCard>
        </div>
        <div class="flex lg:hidden flex-col space-y-5">
            <CourseCard v-for="{ group, course }  in selectedCourses"
              :collapsed="!expandedCourseIds.includes(course.coursId)"
              :group="group"
              :key="course.coursId"
              :checkedVariantId="course.id"
              :detail="getDetailById(group[0].produitId)"
              :isStage="isStage"
              @courseProgramClick="(group, course) => $emit('courseProgramClick', group, course)"
              @variantChange="handleVariantChange"
              class="active pr-12">
                <template #top-right>
                    <div
                      @click="handleExpandCourseClick(course.coursId)"
                      :style="{ width: '40px', height: '40px'}"
                      class="cursor-pointer inline-flex rounded-full bg-black text-white justify-center items-center">
                        <IconArrowheadTop v-if="expandedCourseIds.includes(course.coursId)" style="width: 16px"/>
                        <IconArrowheadDown v-else style="width: 16px"/>
                    </div>
                </template>
                <template #bottom>
                    <BaseButton
                      class="mb-4 base-button-color font-bold"
                      @click="$emit('removeCourseClick', group[0].id)">
                        Déselectionner
                    </BaseButton>
                </template>
            </CourseCard>
        </div>
        <BaseButton
          class="base-button-outline mt-5 font-bold px-6 lg:hidden inline-flex self-start"
          @click="$emit('addCourseClick')">
            + J'ajoute un stage
        </BaseButton>
    </div>
    <div class="basis-full lg:basis-1/2">
        <template v-if="contactFormCustomComponentLoaded">
            <div v-if="contactFormLoading" class="flex justify-center items-center py-5">
                <BaseLoader class="w-16"/>
            </div>
            <ContactForm :key="contactFormPrefilledModel"
              @loaded="contactFormLoading = false"
              @success="$emit('success')"
              @modelChanged="handleModelChanged"
              :class="{ hidden: contactFormLoading }"
              :prefilled-model="contactFormPrefilledModel"
              :landing-name="landing.contact_form.name"></ContactForm>
        </template>
    </div>
</div>

</template>

<style lang="stylus">
.basket-screen
    .course-card
        box-shadow 0px 0px 3px 3px rgba(0,0,0,0.07)
</style>