<script>
import IconDone from '@/components/icons/IconDone'

export default {
    name: 'BaseCheckbox',

    model: {
        prop: 'checked',
        event: 'change',
    },

    components: {
        IconDone,
    },

    props: {
        checked: {
            type: [Array, Boolean],
            default: false,
        },

        value: {
            default: null,
        },
    },

    data() {
        return {
            uid: undefined,
        }
    },

    computed: {
        proxyChecked: {
            get() {
                return this.checked
            },
            set(val) {
                this.$emit('change', val)
            },
        },
    },

    mounted() {
        const inBrowser = typeof window !== 'undefined'
        if (inBrowser) {
            this.uid = 'checkbox' + Math.random()
        }
    },
}
</script>

<template>
    <div
        class="base-checkbox"
        :class="{
            'is-checked': Array.isArray(checked)
                ? checked.includes(value)
                : checked,
        }"
    >
        <div class="input-wrapper">
            <div class="base-checkbox-indicator">
                <slot name="indicator">
                    <IconDone />
                </slot>
                <input
                    type="checkbox"
                    :id="`${uid}`"
                    :value="value"
                    v-model="proxyChecked"
                />
            </div>
        </div>
        <label :for="`${uid}`">
            <div class="label-text">
                <slot name="label" />
            </div>
        </label>
    </div>
</template>

<style lang="stylus">
@import '../assets/stylus/vars.styl'

.base-checkbox
    display inline-flex
    align-items center

    label
        margin-left 5px

    .input-wrapper
        border-radius 4px
        border solid 1px var(--color3)
        width 22px
        height 22px
        position relative
        cursor pointer
        background $AkWhite
        flex-shrink 0

    .base-checkbox-indicator
        position absolute
        top 0
        bottom 0
        left 0
        right 0
        display flex
        align-items center
        justify-content center
        color #fff
        opacity 0

    input
        opacity 0
        position absolute
        top 0
        left 0
        width 100%
        height 100%
        padding 0
        margin 0
        cursor pointer

    &.is-checked .input-wrapper
        background-color var(--AkWhite)
        border-color var(--color3)

        .base-checkbox-indicator
            opacity 1        
            color var(--AkBlack)

    &.base-checkbox-round
        .input-wrapper
            border-radius 50%

        .indicator-dot
            width 12px
            height 12px
            position absolute
            top 50%
            left 50%
            transform translate(-50%, -50%)
            background var(--AkBlack)
            border-radius 50%

        &.is-checked .input-wrapper
            border solid 2px var(--color3)
            background transparent
            width 20px
            height 20px
</style>
