<script>
import BaseButton from '@/components/BaseButton'

function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = (error) => reject(error)
    })
}
export default {
    name: 'FieldUpload',

    inheritAttrs: false,

    props: {
        multiple: {
            type: Boolean,
            default: false,
        },

        value: {
            type: Array,
        },
    },

    components: {
        BaseButton
    },

    data () {
        return {
            dragover: undefined,
            focused: false,
            uid: undefined
        }
    },

    mounted() {
        const inBrowser = typeof window !== 'undefined'
        if (inBrowser) {
            this.uid = this.$attrs.id ? this.$attrs.id : 'file-upload' + Math.random()
        }

        ['drag', 'dragstart', 'dragend', 'dragover', 'dragenter', 'dragleave', 'drop'].forEach((eventName) => {
            this.$el.addEventListener(eventName, (e) => {
                e.preventDefault()
                e.stopPropagation()
            })
        });

        ['dragover', 'dragenter'].forEach((eventName) => {
            this.$el.addEventListener(eventName, () => {
                this.dragover = true
            })
        });

        ['dragleave', 'dragend', 'drop'].forEach((eventName) => {
            this.$el.addEventListener(eventName, () => {
                this.dragover = false
            })
        });

        ['drop'].forEach((eventName) => {
            this.$el.addEventListener(eventName, (e) => {
                this.handleFiles(e.dataTransfer.files)
            })
        })
    },

    methods: {
        handleFiles(files) {
            if (this.multiple) {
                files.forEach((file) => {
                    getBase64(file).then((base64) => {
                        const clone = (this.value || []).slice(0)
                        clone.push({
                            file,
                            base64,
                        })
                        this.$emit('valueChange', clone)
                    })
                })
            } else {
                getBase64(files[0]).then((base64) => {
                    this.$emit('valueChange', [{
                        file: files[0],
                        base64,
                    }])
                })
            }
        },

        handleInputChange($event) {
            const files = $event.currentTarget.files
            this.handleFiles(files)
        },

        handleRemoveClick(index) {
            const clone = (this.value || []).slice(0)
            clone.splice(index, 1)
            this.$emit('valueChange', clone)
        },
    },
}
</script>
<template>
    <div class="base-file-upload"
      :class="{
        ...$attrs.class,
        'has-value': value && value.length,
        'is-dragover': dragover,
        'focused': focused
    }">
        <label :for="uid">
            <div class="label-text">
                <slot name="label">
                    <BaseButton class="base-button-solid">Choisir un fichier</BaseButton>
                </slot>
            </div>
        </label>
        <div class="input-wrapper">
            <div class="before">
                <slot name="before" />
            </div>

            <input
              ref="input"
              type="file"
              :id="uid"
              @change="handleInputChange($event)"
              @focus="focused = true"
              @blur="focused = false"
              v-bind="$attrs"
              v-on="$listeners"/>

            <div class="after">
                <slot name="after" />
            </div>
        </div>

        <div class="files">
            <div v-for="(file, i) in value" :key="file.file.name" class="file">
                <span class="file-name">{{ file.file.name }}</span>
                <span class="file-remove" @click="handleRemoveClick(i)">&times;</span>
            </div>
        </div>
    </div>
</template>

<style lang="stylus">
@import '../assets/stylus/vars.styl'

.base-file-upload
    .input-wrapper
        input
            display none

    .files
        padding-top 10px

        .file
            align-items center
            display flex
            justify-content space-between
            padding 10px
            background-color var(--AkGreyscaleLtGrey)

        .file-remove
            display block
            padding 8px
            background var(--color3)
            font-size 18px
            cursor pointer
</style>
