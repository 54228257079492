<script>
import intlTelInput from 'intl-tel-input'
import 'intl-tel-input/build/css/intlTelInput.css'
export default {
    name: 'BasePhoneNumber',

    inheritAttrs: false,

    components: {},

    props: {
        value: {
            type: [String, Object],
        },

        options: {
            type: Object,
            default: function() {
                return {}
            }
        },
    },

    data() {
        return {
            focused: false,
            typedValue: undefined,
            uid: undefined,
            iti: undefined,
            inBrowser: typeof window !== 'undefined',
        }
    },

    watch: {
        value: {
            handler: function () {
                if (typeof this.value === 'string') {
                    this.typedValue = this.value
                    this.iti?.setNumber(this.typedValue)
                }
            },

            immediate: true,
        },
    },

    mounted() {
        if (this.inBrowser) {
            this.uid = this.$attrs.id ? this.$attrs.id : 'phone-number' + Math.random()
            window.addEventListener('click', this.handleClick)

            this.$nextTick(async () => {
                const input = this.$refs[this.uid]
                this.iti = intlTelInput(input, {
                    preferredCountries: [
                        'fr', 'gb', 'de','be', 'es', 'it', 'lu', 'ch'
                    ],
                    utilsScript:
                        'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.3/js/utils.min.js',
                    initialCountry: 'auto',
                    geoIpLookup: function (success) {
                        fetch('https://ip2c.org/s')
                            .then((response) => response.text())
                            .then((data) => {
                                const [status, country] = String(data).split(';')
                                if (status !== '1') {
                                    throw new Error('Unable to fetch country')
                                }
                                success(country)
                            })
                            .catch(() => {
                                success('FR')
                            })
                    },
                    ...this.options,
                })

                await this.iti.promise

                if (typeof this.value === 'string') {
                    this.typedValue = this.value
                    this.iti.setNumber(this.value)
                    this.handleInput(this.value)
                    this.handleBlur()
                } else if (this.value) {
                    this.typedValue = this.value.number
                    this.iti.setNumber(this.value.number)
                    this.handleInput(this.value.number)
                    this.handleBlur()
                }
            })
        }
    },

    beforeDestroy() {
        window.removeEventListener('click', this.handleClick)
        this.iti.destroy()
    },

    methods: {
        handleInput(value) {
            if (!this.iti || !window || !window.intlTelInputUtils) { return }

            this.$emit('valueChange', value, {
                valid: this.iti.isValidNumber(),
                countryCallingCode: this.iti.getSelectedCountryData().dialCode,
                nationalNumber: this.iti.getNumber(window.intlTelInputUtils.numberFormat.NATIONAL),
                number: this.iti.getNumber(window.intlTelInputUtils.numberFormat.INTERNATIONAL).replaceAll(' ', ''),
                country: this.iti.getSelectedCountryData(),
                countryCode: this.iti.getSelectedCountryData().iso2,
                formatted: this.iti.getNumber(window.intlTelInputUtils.numberFormat.INTERNATIONAL),
            })
        },

        handleFocus() {
            this.focused = true
        },

        handleBlur() {
            if (!this.iti || !window || !window.intlTelInputUtils) { return }
            const countryCode = this.iti.getSelectedCountryData().iso2
            const number = this.iti.getNumber(window.intlTelInputUtils.numberFormat.INTERNATIONAL)
            const formattedNumber = window.intlTelInputUtils.formatNumber(
                number,
                countryCode,
                window.intlTelInputUtils.numberFormat.NATIONAL
            )

            this.typedValue = formattedNumber
            this.iti.setNumber(formattedNumber)

        },

        handleClick(event) {
            if (
                !(this.$el == event.target || this.$el.contains(event.target))
            ) {
                this.focused = false
            }
        },

        handleCountryChange() {
            this.handleInput(this.typedValue)
        }
    },
}
</script>

<template>
    <div
        class="base-phone-number base-input"
        :class="{
            ...$attrs.class,
            'has-value': (typedValue && typedValue.length) || value,
            focused: focused,
        }"
    >
        <label :for="`${uid}`">
            <div class="label-text">
                <slot name="label" />
            </div>
        </label>
        <div class="input-wrapper">
            <div class="before">
                <slot name="before" />
            </div>
            <input
                type="tel"
                v-if="inBrowser"
                @countrychange="handleCountryChange"
                @focus="handleFocus"
                @blur="handleBlur"
                @input="handleInput($event.currentTarget.value)"
                :ref="`${uid}`"
                :id="`${uid}`"
                v-on="$listeners"
                v-bind="$attrs"
                v-model="typedValue"
            />
            <div class="after">
                <slot name="after" />
            </div>
        </div>
    </div>
</template>
<style lang="stylus">
@import '../assets/stylus/vars.styl'

.base-input.base-phone-number
    font-size 1.4em
    display block
    position relative
    cursor text

    .iti
        align-self stretch
        flex-grow 1

        .iti__flag-container
            z-index 3

        input
            flex-grow 1
            width 100%

    .label-text
    input
        // padding-left 1em
        // padding-right 1em

    label
        padding-left 2em
        cursor text
        z-index 1
        display block
        padding-bottom 3px

    .label-text
        display block
        // color $AkGreyscaleBlack
        color $AkBlack

    .input-wrapper
        box-sizing border-box
        display flex
        align-items center
        opacity 1
        border 1px solid var(--color3)
        border-radius 9999px
        background $AkWhite
        height 3em
        font-size inherit
        padding-left 2em
        padding-right 2em

        input
            flex-grow 1
            flex-shrink 1
            border 0
            min-width 0
            height 100%
            box-sizing border-box
            color var(--AkGreyscaleBlack)
            font-family inherit
            font-size inherit

            &::placeholder
                color var(--AkGreyscaleMediumGrey)

            &:focus
                outline 0

                &::placeholder
                    color var(--AkGreyscaleMediumGrey)

    &.focused
    &.has-value
        label
            z-index 0

        .label-text
            font-weight normal
            color var(--color5)

        .input-wrapper
            opacity 1

    &.focused
        border-color var(--color5)

    &.has-errors:not(.focused)
        .input-wrapper
            border-color $AkMajorCoral

        .label-text
            color $AkMajorCoral

        .after
        .before
            color $AkMajorCoral

    &.is-success:not(.focused)
        .input-wrapper
            border-color $AkMainGreen

        .label-text
            color $AkMainGreen

        .after
        .before
            color $AkMainGreen

    &.base-input-small
        font-size 1.15em
</style>
