<script>
export default {
    name: 'BaseRadio',

    components: {
    },

    props: {
        value: {
            type: String,
        },

        options: {
            type: Array,
        },
    },

    data() {
        return {
            uid: undefined,
            isTouchDevice: undefined
        }
    },

    mounted() {
        const inBrowser = typeof window !== 'undefined'
        if (inBrowser) {
            this.uid = 'radio' + Math.random()
            this.isTouchDevice = 'ontouchstart' in document.documentElement
        }
    },
}
</script>

<template>
    <div class="base-radio" :class="{ 'not-touch-device': !isTouchDevice }">
        <div class="main-label">
            <slot name="main-label" />
        </div>
        <div class="options-wrapper">
            <div class="before">
                <slot name="before" />
            </div>
            <label
                v-for="(option, i) in options"
                :key="option.name"
                :id="option.id"
                :class="[{ 'is-checked': value === option.value }, option.classname]"
            >
                <div class="input-wrapper">
                    <div class="base-radio-indicator">
                        <slot name="indicator">
                            <div class="indicator-dot"></div>
                        </slot>
                        <input
                            type="radio"
                            v-on="$listeners"
                            :value="option.value"
                            :id="`${uid}_${i}`"
                            v-bind="$attrs"
                            :checked="value === option.value"
                        />
                    </div>
                </div>
                <div class="label-text">
                    <slot name="label" :option="option">
                        {{ option.name }}
                    </slot>
                </div>
                <div class="after-option">
                    <slot name="after-option">
                    </slot>
                </div>
            </label>
            <div class="after">
                <slot name="after" />
            </div>
        </div>
    </div>
</template>

<style lang="stylus">
@import '../assets/stylus/vars.styl'

.base-radio
    .options-wrapper > label + label
        margin-left 16px

    .before:empty
    .after:empty
        display none

    .main-label
        cursor text
        z-index 1
        padding-bottom 5px
        padding-left 2em
        display block
        color $AkBlack
        font-size 16px

    label
        display inline-flex
        align-items center

    .label-text
        font-size 14px
        color $AkGreyscaleBlack
        margin-left 10px
        flex-grow 1

    .options-wrapper
        display inline-flex
        align-items center

    .input-wrapper
        outline 0
        box-shadow 0 0 0 1px $AkGreyscaleMediumGrey
        width 16px
        height 16px
        position relative
        cursor pointer
        border-radius 50%
        flex-shrink 0

    .base-radio-indicator
        position absolute
        top 0
        bottom 0
        left 0
        right 0
        display flex
        align-items center
        justify-content center
        color #fff
        opacity 0

    input
        opacity 0
        position absolute
        top 0
        left 0
        width 100%
        height 100%
        padding 0
        margin 0
        cursor pointer

    .indicator-dot
        width 10px
        height 10px
        position absolute
        top 50%
        left 50%
        transform translate(-50%, -50%)
        background var(--colorMain)
        border-radius 50%

    .is-checked .input-wrapper
        background-color var(--colorMain)
        box-shadow 0 0 0 2px var(--colorMain)
        background transparent
        width 16px
        height 16px

        .base-radio-indicator
            opacity 1

.base-radio-bar
    .options-wrapper
        display block

    &.not-touch-device label:hover
        // duplicated below in is-checked
        background-color var(--color3)
        border-color var(--color3)
        color var(--color3Inverse)

        .label-text
            color var(--color3Inverse)

        .base-radio-indicator
            opacity 1

        .input-wrapper
            outline 0
            box-shadow 0 0 0 2px var(--color3Inverse)
            background transparent
            width 16px
            height 16px

        .indicator-dot
            opacity 1
            background var(--color3Inverse)

    .options-wrapper label + label
        margin-top 16px
        margin-left 0

    label
        transition background-color ease .3s
        background-color $AkWhite
        border 1px solid var(--color3)
        display flex
        align-items center
        min-height 64px
        padding-left 16px
        padding-right 16px
        flex-direction row
        justify-content start
        cursor pointer
        border-radius 10px
        position relative

        .indicator-dot
            width 8px
            height 8px
            position absolute
            top 50%
            left 50%
            transform translate(-50%, -50%)
            background var(--colorMain)
            border-radius 50%

        &.is-checked
            background-color var(--color3)
            border-color var(--color3)
            color var(--color3Inverse)

            .label-text
                color var(--color3Inverse)
            
            .base-radio-indicator
                opacity 1

            .input-wrapper
                outline 0
                box-shadow 0 0 0 2px var(--color3Inverse)
                background transparent
                width 16px
                height 16px
                
            .indicator-dot
                opacity 1
                background var(--color3Inverse)
            
    input
        margin 0
        padding 0
        
    .label-text
        font-size 14px
        color $AkGreyscaleBlack

.base-radio-switch
    &.not-touch-device label:hover
        // duplicated below in is-checked
        background-color var(--color3)
        border-color transparent
        padding-top 10px
        padding-bottom 10px
        padding-left 25px
        padding-right 25px

        .label-text
            color var(--color3Inverse)
            margin 0

    .options-wrapper
        color var(--color7Inverse)
        border-radius 30px

    label
        transition background-color ease .3s
        border: 1px solid var(--color3)
        border-radius 9999px
        display flex
        align-items center
        padding-top 10px
        padding-bottom 10px
        padding-left 25px
        padding-right 25px
        flex-direction row
        justify-content start
        cursor pointer

        .label-text
            margin 0

        &.is-checked
            background-color var(--color3)
            border-color transparent
            padding-top 10px
            padding-bottom 10px
            padding-left 25px
            padding-right 25px

            .label-text
                color var(--color3Inverse)
                margin 0

        .input-wrapper
            display none

.base-radio-button
    &.not-touch-device label:hover
        // duplicated below in is-checked
        background-color var(--color3)
        border 1px solid var(--color3)

        .label-text
            color var(--color3Inverse)

        .input-wrapper
            outline 0
            box-shadow 0 0 0 2px var(--AkWhite)
            background var(--AkWhite)
            width 16px
            height 16px

        .base-radio-indicator
            opacity 1

        .indicator-dot
            opacity 1
            background var(--color9)

        .options-wrapper
            display inline-flex
            align-items center

            & > label + label
                margin-left 16px

    .input-wrapper
        border-radius 50%
        outline 0
        box-shadow 0 0 0 1px var(--color3)

    .indicator-dot
        width 12px
        height 12px
        position absolute
        top 50%
        left 50%
        transform translate(-50%, -50%)
        background var(--color3)
        border-radius 50%

    label
        transition background-color ease .3s
        border 1px solid var(--color3)
        border-radius 9999px
        background $AkWhite
        display flex
        align-items center
        padding-top 10px
        padding-bottom 10px
        padding-left 20px
        padding-right 20px
        flex-direction row
        justify-content start
        cursor pointer

        &.is-checked
            background-color var(--color3)
            border 1px solid var(--color3)

            .label-text
                color var(--color3Inverse)
                
            .input-wrapper
                outline 0
                box-shadow 0 0 0 2px var(--AkWhite)
                background var(--AkWhite)
                width 16px
                height 16px

            .base-radio-indicator
                opacity 1

            .indicator-dot
                opacity 1
                background var(--color9)

</style>
