<script>
export default {
    name: 'BaseSelect',

    inheritAttrs: false,

    props: {
        value: {
            type: [String, Number],
        },

        placeholder: {
            type: String,
            default: undefined,
        },

        options: {
            type: Array,
        },
    },

    data() {
        return {
            focused: false,
            uid: undefined,
        }
    },

    mounted() {
        const inBrowser = typeof window !== 'undefined'
        if (inBrowser) {
            this.uid = this.$attrs.id ? this.$attrs.id : 'select' + Math.random()
        }
    },
}
</script>

<template>
    <div
        class="base-select"
        :class="{
            ...$attrs.class,
            'has-value': value && value.length,
            focused: focused,
        }"
    >
        <label :for="`${uid}`">
            <div class="label-text">
                <slot name="label" />
            </div>
        </label>
        <div class="select-wrapper">
            <div class="before">
                <slot name="before" />
            </div>
            <select
                @focus="focused = true"
                @blur="focused = false"
                v-on="$listeners"
                :value="value"
                :id="`${uid}`"
                v-bind="$attrs"
            >
                <option v-if="placeholder" hidden disabled selected value>
                    {{ placeholder }}
                </option>
                <option  
                    v-for="option in options"
                    :key="option.value"
                    :value="option.value"
                    class="option-select"
                    v-bind="option"
                >
                    {{ option.name }}
                </option>
            </select>
            <div class="after">
                <slot name="after" />
            </div>
        </div>
    </div>
</template>
<style lang="stylus">
@import '../assets/stylus/vars.styl'

.base-select
    position relative
    font-size 1.4em

    label
        color $AkBlack
        display block
        padding-bottom 5px
        padding-left 28px

    .select-wrapper
        display flex
        align-items center
        color $AkGreyscaleDarkGrey
        background-color #fff
        border-radius 120px
        padding-left 24px
        padding-right 24px
        height 3em
        border 1px solid var(--color3)

        select
            flex-grow 1
            flex-shrink 1
            border 0
            min-width 0
            box-sizing border-box
            color var(--AkGreyscaleBlack)
            font-family inherit
            font-size inherit
            background transparent
            font-size 16px
            height 100%

            &:focus
                outline 0

        option
            color black

    &:not(.has-value)
        // Placeholder style
        select
            color var(--AkGreyscaleMediumGrey)
            font-weight normal
            font-size 16px

    &.focused
        border-color var(--color5)

    &.has-errors:not(.focused)
        .select-wrapper
            border-color $AkMajorCoral

        .label-text
            color $AkMajorCoral

        .after
        .before
            color $AkMajorCoral

    &.is-success:not(.focused)
        .select-wrapper
            border-color $AkMainGreen

        .label-text
            color $AkMainGreen

        .after
        .before
            color $AkMainGreen

    &.base-select-small
        font-size 1.15em

        .label-text
            font-size .9em
</style>
